import React, {useMemo, useState, useEffect} from "react";
import Cookies from "js-cookie";
import {Input, Modal, Select, Typography} from "antd";
import api from "../../utils/api";
import {toast} from "react-toastify";
import {Button} from "@mui/material";
import useStore from "../../Store"
import { useNavigate } from 'react-router-dom';
const { Text } = Typography;



const EditProjectModal = ({open, setIsModalOpen ,id, setProjectName, projectName, project }) => {
    const [name, setName] = useState("")
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [buttonColor, setButtonColor] = useState('grey');
    const token = Cookies.get("access_token");
    const navigate = useNavigate();
    const {updateStoreProject, storeProjects} = useStore()
    useEffect(() => {
        setName(projectName);
    }, [projectName]);
    const handleOk = async () => {
        try {
            if (!token) {
                throw new Error("No access token found");
            }

            const response = await api.patch(
                `/projects/${id}/`,
                { name },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            toast.success("Project Edited successfully");
            setProjectName(name)
            updateStoreProject(id,name)
            handleCancel();


        } catch (err) {
            toast.error("Error Edited project. Please try again.");
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        // setName('');
        setIsButtonDisabled(true);
        setButtonColor('grey');
    };


    const handleNameChange = (e) => {
        const newName = e.target.value;
        setName(newName);
        validateForm(newName);
    };


    const validateForm = (name, ) => {
        if (name) {
            setIsButtonDisabled(false);
            setButtonColor("black");
        } else {
            setIsButtonDisabled(true);
            setButtonColor("grey");
        }
    };



    return (
        <div>
            <Modal
                open={open}
                onOk={handleOk}
                onCancel={handleCancel}
                width={700}
                footer={[
                    <Button
                        key="submit"
                        type="primary"
                        onClick={handleOk}
                        disabled={isButtonDisabled}
                        style={{backgroundColor: buttonColor, borderColor: buttonColor, color: 'white'}}
                    >
                        Save
                    </Button>,
                ]}
            >
                <Typography.Title level={2}>Rename project</Typography.Title>
                <Text strong>*Name</Text>
                <div>
                    <Input
                        placeholder="Project Name"
                        value={name}
                        onChange={handleNameChange}
                        type="text"
                        required
                        style={{width: 400, marginTop: 5, marginBottom: 15}}
                    />
                </div>
            </Modal>
        </div>
    );
}
export default EditProjectModal;
