import React, {useEffect, useMemo, useState } from "react";
import "./custom.css";
import ProjectsPage from "../../pages/ProjectsPage";
import CreateProjectModal from "../projects/CreateProjectModal";
import {useLocation} from "react-router-dom";
import useStore from "../../Store";
const DashboardSplash = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [screen1, setScreen1] = useState(true);
  const [screen2, setScreen2] = useState(false);
  const [dashboard, setDashboard] = useState(true);
  const { storeProjects} = useStore();
  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };


  const showModal = () => {
    setIsModalOpen(true);
    setIsOpen(false);
  };
  useEffect(() => {
    if (storeProjects && storeProjects.length > 0) {
      setScreen2(true)
    }
  }, []);

  return (
    <div className="main_container">
      <div className="content_container_splash">
        <div className={`drawer ${isOpen ? "open" : ""}`}>
          <div className="drawer-content2">
            <h4>My Projects</h4>
            <p>
              There are no projects yet, Get started by creating a new project
            </p>
            <button
              className="createaccountbtn"
              style={{
                backgroundColor: "#262626",
                color: "white",
                cursor: "pointer"
              }}
              onClick={showModal}
            >
              Create a New Project
            </button>
          </div>
        </div>
        {!screen2 && (
          <div className="img_round_main_dashboard">
            <h1 className="forgetHead_text">Welcome to Breevy</h1>
            <p>
              Get started by creating your first project and unlocking the full
              potential of our SEO system.
            </p>
            <button
              className="createaccountbtn"
              style={{
                backgroundColor: "#262626",
                color: "white",
                cursor: "pointer",
              }}
              onClick={toggleDrawer}
            >
              Create a New Project
            </button>
          </div>
        )}
        {screen2 && <ProjectsPage  />}
      </div>


      <CreateProjectModal open={isModalOpen}
                          setIsModalOpen={setIsModalOpen}
                          isDashboard={dashboard} setScreen1={setScreen1} setScreen2={setScreen2} />

    </div>
  );
};

export default DashboardSplash;
