import Logo from "../../assets/logo.png";
import Icon from "../../assets/fi_9356210.svg";
import React, { useState} from "react";

const ProjectNav = ({projectName, setIsOpen, setIsModalOpen}) => {
    const [docModalOpen, setDocModalOpen]= useState(false)
    const showModal = () => {
        setIsModalOpen(true);
        setIsOpen(false);

    };

    const showDocModal = () => {
        setDocModalOpen(true)
    }
    return (
        <>
        <div className="main-div">
            <div className="div-1">

                <img className="div-1-img-1" src={Logo} alt="Home"/>
                <div style={{fontWeight: 700, fontSize: '18px', fontFamily: 'sans-serif'}}>
                    {projectName}
                </div>
                <img className="div-1-img-2" src={Icon} alt="Edit" onClick={showModal}/>
            </div>

            <div className="main-div-2">
                {/*<div className="div-2">*/}
                {/*  Target audience*/}
                {/*  <img className="div-2-img-1" src="IMages/Vector.svg" alt=""/>*/}
                {/*</div>*/}
                <div className="newDoc" onClick={showDocModal}>New Document</div>
            </div>
        </div>

            </>
    )

}
export default ProjectNav;

