import React from "react";
import Emailimg from "../../../assets/email.png";
import { Link } from "react-router-dom";
const SignUpSplash = () => {
  return (
    <div className="loginsplashheading">
      <h1>Create account</h1>
      <Link to="/signup">
        <div className="continuewithemail">
          <img src={Emailimg} alt="" />
          Continue with email
        </div>
      </Link>
    </div>
  );
};

export default SignUpSplash;
