import React from "react";

const ProjectDocumentsCard = ({ projectName, title, seoScore, status, date, documentBreif, noCard }) => {
    return (
        <div className="cnt-2-div-1">
            {noCard ? (
                <div className="no-card-message" style={{height:"60vh"}}>
                    <h5>No Document Available</h5>
                </div>
            ) : (
                <>
                    <div className="cnt-2-1">
                        {projectName && projectName}
                    </div>
                    <div className="cnt-2-2">
                        {title && title}
                    </div>
                    <div className="cnt-2-3">
                        {documentBreif !== '' ? `${documentBreif}` : "No description available"}
                    </div>
                    <div className="cnt-2-4">
                        SEO Score
                    </div>
                    <div className="cnt-2-5">
                        {seoScore !== null ? `${seoScore}%` : '- %'}
                    </div>
                    <div className="cnt-2-6">
                        <div className="cnt-2-7">
                            {status && (
                                <span className={`status ${status.toLowerCase().replace(" ", "-")}`}>
                                    {status}
                                </span>
                            )}
                        </div>
                        <div className="cnt-2-3">
                            {date && date}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default ProjectDocumentsCard;
