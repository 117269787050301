import React from "react";
import Emailimg from "../../../assets/email.png";
import { Link } from "react-router-dom";
const LoginSplash = () => {
    return (
        <div className="loginsplashheading">
            <h1>Login</h1>
            <Link to="/login-email">
                <div className="continuewithemail">
                    <img src={Emailimg} alt="" />
                    Continue with email
                </div>
            </Link>
        </div>
    );
};

export default LoginSplash;
