import React, { useState } from "react";
import { Input } from "antd";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import BackArrow from "../../assets/backarrow.png";
import { toast } from "react-toastify";

const ForgotPassword = () => {
    const [email, setEmail] = useState("");
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async () => {
        setLoading(true);
        setError(null);
        try {
            await axios.post("/api/forgot-password/", { email });
            localStorage.setItem("forgot-password-email", email);
            toast.success("Reset password email has been sent. Please check your inbox.", {
                autoClose: 2000 // 2 seconds for success
            });
        } catch (err) {
            console.error("Error:", err);
            if (err.response && err.response.data && err.response.data && err.response.data.non_field_errors) {
                // Handle specific field errors
                const errorMessages = Object.values(err.response.data.non_field_errors).flat();
                errorMessages.forEach(message => toast.error(message));
              }else if (err.response.data.email)
            {
                toast.error(err.response.data.email);
            } else{

                toast.error("User with this email doesn't exist");
              }
        } finally {
            setLoading(false);
        }
    };
    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const emailIsValid = isValidEmail(email);

    return (
        <div className="register-main-container">
            <Link to="/login-email">
                <div className="back-arrow" style={{ marginBottom: 30 }}>
                    <img src={BackArrow} alt="Back" />
                </div>
            </Link>
            <h1 className="forgetHead_text">Forgot Your Password?</h1>
            <h1 className="forgetHead_text">No Worries!</h1>
            <p>Enter your email, and we'll help you reset your password</p>
            {error && <div className="error-message">{error}</div>}
            <Input
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                required
            />
            <div className="margin-top">
                <button
                    onClick={handleSubmit}
                    disabled={!emailIsValid}
                    className="createaccountbtn"
                    style={{
                        backgroundColor: emailIsValid ? "black" : "grey",
                        color: "white",
                        cursor: emailIsValid ? "pointer" : "not-allowed",
                    }}
                >
                    {loading ? "Sending..." : "Send Reset Link"}
                </button>
            </div>
        </div>
    );
};

export default ForgotPassword;
