import React from "react";
import Sidebar from "../components/Sidebar";
import Drawer from "../components/Drawer";
import { Outlet } from "react-router-dom";
const Dashboard = () => {
  return (
    <div className="main_container">
      <div className="side_bar">
        <Sidebar />
        <Drawer />
      </div>
      <div className="content-container-main-dashboard">
        <Outlet />
      </div>
    </div>
  );
};

export default Dashboard;
