import React, { useState } from "react";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Input } from "antd";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";  // Keep using the standard axios import as in your original code
import BackArrow from "../../../assets/backarrow.png";
import { toast } from "react-toastify";

const Register = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleRegister = async () => {
    setLoading(true);
    setErrors({});
    try {
      await axios.post("/api/register/", { email, password });
      toast.success("Account has been created successfully.");
      navigate("/login"); // Redirect to login page
    } catch (err) {
      if (err.response && err.response.data && err.response.data.errors) {
        // Handle specific field errors
        const errorMessages = Object.values(err.response.data.errors).flat();
        errorMessages.forEach(message => toast.error(message));
      } else {
        // Fallback general error message
        toast.error("Registration failed. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isValidPassword = (password) => {
    return password.length >= 6;
  };

  const isFormValid = isValidEmail(email) && isValidPassword(password);

  return (
    <div className="register-main-container">
      <Link to="/">
        <div className="back-arrow">
          <img src={BackArrow} alt="Back" />
        </div>
      </Link>
      <h1>Create account with email</h1>

      <div>
        <Input
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          type="email"
          required
          className={errors.email ? "input-error" : ""}
        />
        {errors.email && (
          <div className="error-message">{errors.email[0]}</div>
        )}
      </div>
      <div className="margin-top">
        <Input.Password
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          iconRender={(visible) =>
            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
          required
          className={errors.password ? "input-error" : ""}
        />
        {errors.password && (
          <div className="error-message">{errors.password[0]}</div>
        )}
      </div>
      <div className="margin-top">
        <button
          onClick={handleRegister}
          className="createaccountbtn"
          disabled={!isFormValid || loading}
          style={{
            backgroundColor: isFormValid ? "black" : "grey",
            color: "white",
            cursor: isFormValid ? "pointer" : "not-allowed",
          }}
        >
          {loading ? "Creating Account..." : "Create account"}
        </button>
      </div>
    </div>
  );
};

export default Register;
