import React from "react";
import { Link, useLocation } from "react-router-dom";
import Logoimg from "../../../assets/logo.png";

const RegisterSidebar = () => {
  const location = useLocation();

  const type = () => {
    if (location.pathname === "/" || location.pathname === "/signup"  ) {
      return <h2> Sign Up </h2>;
    } else  {
      return <h2> Back </h2>;
    }
  };

  return (
    <div className="dashboardsidemain">
      <div className="dashboardheader">
        <Link to="/">
          <img src={Logoimg} alt="" />
        </Link>
        Breevy
      </div>
      <div className="centerheadertextdashboard">
        <h1>Welcome</h1>
        {type()}
      </div>
    </div>
  );
};

export default RegisterSidebar;
