import React, {useMemo, useState} from "react";
import countryList from "react-select-country-list";
import Cookies from "js-cookie";
import {Input, Modal, Select, Typography} from "antd";
import api from "../../utils/api";
import {toast} from "react-toastify";
import {Button} from "@mui/material";
import { useNavigate } from 'react-router-dom';
import fetchProjects from "../../utils/fetchProjects";
import useStore from "../../Store"

const CreateProjectModal = ({open, setIsModalOpen, isDashboard, setScreen1, setScreen2}) => {
    const [name, setName] = useState("")
    const navigate = useNavigate();
    const [url, setUrl] = useState("");
    const [location, setLocation] = useState('');
    const options = useMemo(() => countryList().getData(), [])
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [buttonColor, setButtonColor] = useState('grey');
    const {setProjects, clearProjects} = useStore()

    const token = Cookies.get("access_token");
    const { Option } = Select;
    const { Text } = Typography;


    const handleOk = async () => {
        try {
            if (!token) {
                throw new Error("No access token found");
            }

            const response = await api.post(
                "/projects/",
                { name, url, location },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const projectId = response.data.project.id
            console.log("Project created successfully:", response.data);
            toast.success("Project created successfully");
            handleCancel();
            const projects = await fetchProjects();

            if (! isDashboard) {
                clearProjects()
                setProjects(projects)
                navigate(`/project/${projectId}`);
            } else{
                setScreen1(false);
                setScreen2(true);
                clearProjects()
                setProjects(projects)
                navigate(`/project/${projectId}`);

            }

        } catch (err) {
            console.error("Project creation failed:", err);
            toast.error("Error creating project. Please try again.");
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setUrl('');
        setLocation('');
        setIsButtonDisabled(true);
        setButtonColor('grey');
    };

    const isValidURL = (url) => {
        const regex = /^https?:\/\/[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/[a-zA-Z0-9-._~:\/?#@!$&'()*+,;=]*)?$/;
        return regex.test(url);
    };

    const handleNameChange = (e) => {
        const newName = e.target.value;
        setName(newName);
        validateForm(newName, url, location);
    };

    const handleUrlChange = (e) => {

        const newUrl = e.target.value;
        setUrl(newUrl);
        if (isValidURL(newUrl) && location) {
            console.log(isValidURL(newUrl));
            setIsButtonDisabled(false);
            setButtonColor('black');
        } else {
            setIsButtonDisabled(true);
            setButtonColor('grey');
        }
    };

    const validateForm = (name, url, location) => {
        if (name && isValidURL(url) && location) {
            setIsButtonDisabled(false);
            setButtonColor("black");
        } else {
            setIsButtonDisabled(true);
            setButtonColor("grey");
        }
    };



    return (
    <div>
        <Modal
            open={open}
            onOk={handleOk}
            onCancel={handleCancel}
            width={700}
            footer={[
                <Button
                    key="submit"
                    type="primary"
                    onClick={handleOk}
                    disabled={isButtonDisabled}
                    style={{backgroundColor: buttonColor, borderColor: buttonColor, color: 'white'}}
                >
                    Create Project
                </Button>,
            ]}
        >
            <Typography.Title level={2}>Create a new project</Typography.Title>
            <Text strong>*Name</Text>
            <div>
                <Input
                    placeholder="Project Name"
                    value={name}
                    onChange={handleNameChange}
                    type="text"
                    required
                    style={{width: 400, marginTop: 5, marginBottom: 15}}
                />
            </div>
            <Text strong level={5}>*URL</Text>
            <div>
                <Input
                    placeholder="URL"
                    value={url}
                    onChange={handleUrlChange}
                    type="text"
                    required
                    style={{width: 400, marginTop: 5}}
                />
            </div>
            <Select
                showSearch
                style={{width: 200, marginTop: 15}}
                placeholder="Select location"
                value={location || undefined}
                optionFilterProp="children"
                filterSort={(optionA, optionB) =>
                    (optionA.children.toLowerCase() || '').localeCompare(optionB.children.toLowerCase() || '')
                }
                onChange={(value) => {
                    setLocation(value);
                    if (isValidURL(url) && value) {
                        setIsButtonDisabled(false);
                        setButtonColor('black');
                    } else {
                        setIsButtonDisabled(true);
                        setButtonColor('grey');
                    }
                }}
            >
                {options.map((country) => (
                    <Option key={country.value} value={country.label}>
                        {country.label}
                    </Option>
                ))}
            </Select>
        </Modal>
    </div>
    );
}
export default CreateProjectModal;
