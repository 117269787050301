import create from 'zustand';
import { persist } from 'zustand/middleware';

const useStore = create(
    persist(
        (set, get) => ({
            storeProjects: [],
            DrawerOpener: true,

            setProjects: (newProjects) => set(() => ({
                storeProjects: newProjects
            })),
            clearProjects: () => set({ storeProjects: [] }),

            storeDocuments: [],
            setDocuments: (newDocuments) => set(() => ({
                storeDocuments: newDocuments
            })),
            clearDocuments: () => set({ storeDocuments: [] }),
            updateStoreProject: (id, updatedName) => {
                const { storeProjects } = get();
                const projectId = Number(id)
                const updatedProjects = storeProjects.map((project) =>
                    project.id === projectId ? { ...project, name: updatedName } : project
                );

                set({ storeProjects: updatedProjects });

            },
            setDrawerOpener: (DrawerOpener) => set(() => ({
                DrawerOpener: DrawerOpener
            })),
            clearDrawerOpener: () => set({ DrawerOpener: false }),

        }),
        {
            name: 'projects-storage',
            getStorage: () => localStorage,
        }
    )
);

export default useStore;
