import AddRoundedIcon from "@mui/icons-material/AddRounded";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Divider from "@mui/material/Divider";
import DirectionsIcon from "@mui/icons-material/Directions";
import Side from "../assets/side.png";
import React, { useState} from "react";
import useStore from "../Store";
import CreateProjectModal from "./projects/CreateProjectModal";
import {Link} from "react-router-dom";


const Drawer = () => {
    const { DrawerOpener ,storeProjects} = useStore();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };


    const [searchQuery, setSearchQuery] = useState("");

    const filteredProjects = storeProjects.filter((project) =>

        project.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        project.url.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleModalOpener = () =>{
        setIsModalOpen(true);
    }
    return (
        <div>
            <div className={`drawer ${DrawerOpener ? "open" : ""}`}>
                <div className="drawer-content">
                    <div className="display_draw">
                        <h4>My Projects</h4>
                        <div
                            style={{
                                backgroundColor: "black",
                                borderRadius: "50%",
                                padding: 10,
                                cursor: "pointer",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <AddRoundedIcon style={{color: "white"}}  onClick={handleModalOpener}/>
                        </div>
                    </div>
                    <hr
                        style={{
                            backgroundColor: "#E5E5E5",
                            border: "1px solid #E5E5E5",
                        }}
                    />
                    <Paper
                        component="form"
                        sx={{
                            p: "2px 4px",
                            display: "flex",
                            alignItems: "center",
                            width: 400,
                        }}
                    >
                        <InputBase
                            sx={{ml: 1, flex: 1}}
                            placeholder="Search a project"
                            inputProps={{"aria-label": "Search a project"}}
                            value={searchQuery}
                            onChange={handleSearchChange}
                        />
                        <IconButton type="button" sx={{p: "10px"}} aria-label="search">
                            <SearchIcon/>
                        </IconButton>
                        <Divider sx={{height: 28, m: 0.5}} orientation="vertical"/>
                        <IconButton
                            color="primary"
                            sx={{p: "10px"}}
                            aria-label="directions"
                        >
                            <DirectionsIcon/>
                        </IconButton>
                    </Paper>
                    <hr
                        style={{
                            backgroundColor: "#E5E5E5",
                            border: "1px solid #E5E5E5",
                        }}
                    />
                    <div style={{overflowY: "auto", maxHeight: "650px"}}>
                        {filteredProjects && filteredProjects.length > 0 ? (
                            filteredProjects.map((project) => (
                                <Link to={`/project/${project.id}`} >
                                    <div key={project.id} className="display_draw_inner" >
                                        <img
                                            className="addicon"
                                            src={Side}
                                            alt={`${project.name} project icon`}
                                        />
                                        <h4>{project.name}</h4>
                                    </div>
                                </Link>
                            ))
                        ) : (
                            <div className="no-projects-message">
                                <p>No projects available.</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <CreateProjectModal open={isModalOpen}
                                setIsModalOpen={setIsModalOpen} />
        </div>
    )
};
export default Drawer;