import Sidebar from "../Sidebar";
import Drawer from "../Drawer"

import React from "react";

const Layout =({children})=>{
    return (
        <div className="main_container">
            <div className="side_bar">
                <Sidebar/>
                <Drawer/>
            </div>
            <div className="content-container-main-dashboard">
                {children}
            </div>
        </div>
    );
}

export default Layout