import React, { useState, useEffect } from "react";
import Content from "../components/projects/Content";
import { useLocation, useNavigate } from "react-router-dom";
import fetchProjects from "../utils/fetchProjects";
import useStore from "../Store";

const ProjectsPage = ({projectsShow}) => {
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const { storeProjects} = useStore();

  useEffect(() => {
    const getProjects = async () => {
      try {
        const passedProjects = storeProjects
       if (passedProjects && passedProjects.length > 0) {
          setProjects(passedProjects);
        } else {
          const fetchedProjects = await fetchProjects();
          setProjects(fetchedProjects);

          if (fetchedProjects.length === 0) {
            navigate("/dashboard");
          }
        }
      } catch (error) {
        console.error("Error fetching projects:", error);
        navigate("/dashboard");
      }
    };
    getProjects();
  }, []);

  return (
    <div className="main_container">
      <div className="content_container">
        <Content />
      </div>
    </div>
  );
};

export default ProjectsPage;
