import React, {useEffect, useState} from "react";
import "./Document.css";
import BackArrow from "../../assets/backarrow.png";

import {PiDotsThreeOutlineFill} from "react-icons/pi";
import {AiOutlineSearch} from "react-icons/ai";
import {Input} from "antd";
import {Link, useParams} from "react-router-dom";
import api from "../../utils/api";

import Layout from "../Layout/Layout"
import "./Document.css"

import ProjectNav from "../projects/ProjectNav";
import EditModal from "../projects/EditModal";

const DocumentsList = () => {
    const {id} = useParams();
    const [documents, setDocuments] = useState([])
    const [isOpen, setIsOpen] = useState(true);
    const [projectName, setProjectName] = useState('')
    useEffect(() => {
        const getDocuments = async () => {
            try {
                const response = await api.get(
                    `/project-documents/${id}/`
                );
                setDocuments(response.data.documents)
                setProjectName(response.data.project_name)
            } catch (error) {
                console.error("Error fetching projects:", error);
            }
        };
        getDocuments();

    }, []);
    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const [dropdownStates, setDropdownStates] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);

    // Handle the change for each dropdown
    const handleChange = (e, itemId) => {
        const value = e.target.value;
        setDropdownStates((prevState) => ({
            ...prevState,
            [itemId]: {
                selectedValue: value,
                dropdownClass: getDropdownClass(value),
            }
        }));
    };

    const getDropdownClass = (value) => {
        switch (value) {
            case "pending":
                return "pending";
            case "waiting for approval":
                return "waiting-for-approval";
            case "In progress":
                return "progress";
            case "done":
                return "done";
            case "published":
                return "published";
            default:
                return "";
        }
    };


    const [searchQuery, setSearchQuery] = useState("");

    const filteredDocuments = documents.filter((doc) =>

        doc.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        doc.doc_status.toLowerCase().includes(searchQuery.toLowerCase())
    );
    return (

        <Layout>
            <ProjectNav projectName={projectName} setIsModalOpen={setIsModalOpen} setIsOpen={setIsOpen}/>
            <div className="doc-Container">
                <div className="doc-main-heading">
                    <Link to={`/project/${id}`}>
                        <div className="backarrow">
                            <img src={BackArrow} alt="Back"/>
                        </div>
                    </Link>{" "}
                    <span className="doc-main-heading-content">Documents</span>
                </div>
                <Input
                    placeholder="Search a Document"
                    className="doc-search-input"
                    value={searchQuery}
                    onChange={handleSearchChange}

                    suffix={<AiOutlineSearch className="doc-search-icon"/>}
                />
                {filteredDocuments && filteredDocuments.length > 0 ?
                    filteredDocuments.map((item) => {
                        const { selectedValue = '', dropdownClass = 'default-class' } = dropdownStates[item.id] || {};
                        const statusValue = selectedValue || item.doc_status;
                        const seoScore = item.seo_score ? `${item.seo_score}%` : "-";

                        return (
                            <div key={item.id} className="doc-Content">
                                <div>
                                    <h3 className="doc-title">{item.name}</h3>
                                </div>
                                <div>
                                    <h5 className="doc-type">{item.doc_type}</h5>
                                </div>
                                <div>
                                    <span className="doc-type">SEO Score:</span>
                                    <span className="doc-seo">{seoScore}</span>
                                </div>
                                <div className="doc-status-parent">
                                    <select
                                        className={`status-dropdown ${statusValue.toLowerCase()}`}
                                        id={`customDropdown-${item.id}`}
                                        value={statusValue}
                                        onChange={(e) => handleChange(e, item.id)}
                                    >
                                        <option value="In Progress">In Progress</option>
                                        <option value="Done">Done</option>
                                        <option value="Published">Published</option>
                                        <option value="Waiting for Approval">Waiting for Approval</option>
                                    </select>
                                </div>
                                <div className="doc-type">{item.date}</div>
                                <div>
                                    <PiDotsThreeOutlineFill className="doc-detail" />
                                </div>
                            </div>
                        );
                    })
                    : <div className="img_round_main_dashboard" style={{height: "50%"}}>
                        <p>
                            There are no Documents yet, Get started by creating a new Document
                        </p>
                        <button
                            className="createaccountbtn"
                            style={{
                                backgroundColor: "#262626",
                                color: "white",
                                cursor: "pointer",
                            }}

                        >
                            Create a New Document
                        </button>
                    </div>
                }
            </div>
            <EditModal open={isModalOpen} setIsModalOpen={setIsModalOpen} id={id} projectName={projectName}
                       setProjectName={setProjectName}/>
        </Layout>

    );
};

export default DocumentsList;
