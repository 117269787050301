import React, { useEffect, useState } from "react";
import "./App.css";
import {BrowserRouter as Router, Navigate, Route, Routes} from "react-router-dom";
import ProjectsPage from "./pages/ProjectsPage";
import Auth from "./pages/Auth";
import SignUpSplash from "./components/auth/signup/SignUpSplash";
import Login from "./components/auth/login/Login";
import ForgotPassword from "./components/auth/ForgotPassword";
import Register from "./components/auth/signup/Register";
import ResetPassword from "./components/auth/ResetPassword";
import CookieConsent from "./components/cookies/CookieConsent";
import LoginSplash from "./components/auth/login/LoginSplash"; // Import the component
import { ToastContainer, toast } from "react-toastify";
import DashboardSplash from "./components/DashboardComponents/DashboardSplash";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import "react-toastify/dist/ReactToastify.css";
import Dashboard from "./pages/Dashboard";
import ProjectFolder from "./components/ProjectFolder/ProjectFolder";
import Cookies from "js-cookie";
import ProjectDetails from "./components/projects/ProjectDetails";
import DocumentsList from "./components/Documents/DocumentsList";
function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Check if the user is authenticated by looking for the access token in cookies
    setLoading(false); // Stop loading once authentication check is complete
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Show a loading indicator while checking authentication
  }

    const AuthRoute = ({ element: Component, ...rest }) => {
        const user = Cookies.get("access_token");
        return user ?   <Navigate to="/dashboard" /> : <Component {...rest} />;
    };

  return (
    <Router>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        className="toast-container"
      />
      <Routes>
        <Route path="/" element={<Auth />}>
            <Route path="" element={<AuthRoute element={SignUpSplash} />} />
            <Route path="signup" element={<AuthRoute element={Register} />} /> />
            <Route path="login" element={<AuthRoute element={LoginSplash} />} />
            <Route path="login-email" element={<AuthRoute element={Login} />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="reset-password/:reset_token" element={<ResetPassword />} />
        </Route>

        <Route path="/dashboard" element={<ProtectedRoute element={Dashboard} />}>
          <Route path="" element={<DashboardSplash />} />
          <Route path="folders" element={<ProjectFolder />} />
          <Route path="profile" element={<ProjectFolder />} />
          <Route path="help" element={<ProjectFolder />} />
            <Route path="projects/:id" element={<h1> Project Detail Page </h1>} />
        </Route>
          <Route path="profile" element={<ProjectFolder />} />
          <Route path="project/:id"  element={<ProtectedRoute element={ProjectDetails} />} />
          <Route path="project/:id/documents" element={<ProtectedRoute element={DocumentsList} /> } />
          <Route path="help" element={<ProjectFolder />} />
      </Routes>
      <div>
        <CookieConsent />
      </div>
    </Router>
  );
}

export default App;
