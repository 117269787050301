import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import Logo from "../assets/logo.png";
import Sub from "../assets/Subscription.png";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import FolderRoundedIcon from "@mui/icons-material/FolderRounded";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import useStore from ".././Store"
const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation(); // to get the current path
  const [activePath, setActivePath] = useState("");
  const {clearProjects, clearDocuments, setDrawerOpener , DrawerOpener,clearDrawerOpener} = useStore()

  useEffect(() => {
    // Set the active path when the component mounts or the route changes
    setActivePath(location.pathname);
  }, [location.pathname]);
  const handleLogout = () => {
    // Remove the access token from cookies
    clearProjects()
    clearDocuments()
    clearDrawerOpener()
    Cookies.remove("access_token");


    // Redirect to the login page
    navigate("/login");
  };

  const handleFolderClick =  () =>{
    if (DrawerOpener)
      setDrawerOpener(false)
    else{
      setDrawerOpener(true)
    }
  };

  const handleIconClick = (path) => {
    // Navigate to the specified path
    navigate(path);
    console.log(path);
  };

  return (
      <div className="main_container_sidebar">
        <div className="menu_items">
          <div className="logo">
            <Link to="/">
              <img src={Logo} alt="Home" />
            </Link>
          </div>
          <div className="menus">
            <div
                className={`${activePath === "/dashboard" ? "dash" : ""}`}
                onClick={() => handleIconClick("/dashboard")}
                style={{ cursor: "pointer" }}
            >
              <GridViewRoundedIcon
                  style={{
                    color: activePath === "/dashboard" ? "black" : "white",
                    cursor: "pointer",
                  }}
              />
            </div>
            <div
                className={activePath.startsWith("/project/") ? "dash" : ""}
                onClick={() => handleFolderClick()}
            >
              <FolderRoundedIcon
                  style={{
                    color: activePath.startsWith("/project/") ? "black" : "white",
                    cursor: "pointer",
                  }}
              />
            </div>
            <div
                className={activePath === "/profile" ? "dash" : ""}
                onClick={() => handleIconClick("/profile")}
            >
              <PersonRoundedIcon
                  style={{
                    color: activePath === "/profile" ? "black" : "white",
                    cursor: "pointer",
                  }}
              />
            </div>
            <div
                className={activePath === "/help" ? "dash" : ""}
                onClick={() => handleIconClick("/help")}
            >
              <HelpRoundedIcon
                  style={{
                    color: activePath === "/help" ? "black" : "white",
                    cursor: "pointer",
                  }}
              />
            </div>
          </div>
          <div className="menus" style={{ marginBottom: 50 }}>
            {/* <div
            className={`dash_sub ${
              activePath === "/subscription" ? "dash" : ""
            }`}
            onClick={() => handleIconClick("/subscription")}
          >
            <img
              className="icons_"
              src={Sub}
              alt="Subscription"
              style={{ cursor: "pointer" }}
            />
          </div> */}
            <div onClick={handleLogout}>
              <LogoutRoundedIcon
                  style={{
                    color: activePath === "/logout" ? "black" : "white",
                    cursor: "pointer",
                  }}
              />
            </div>
          </div>
        </div>
      </div>
  );
};

export default Sidebar;