import React, { useState, useEffect } from "react";
import "./CookieConsent.css"; // Assuming you have a CSS file for styling
import Cookieimg from "../../assets/cookie.svg";
const CookieConsent = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Check if the user has already made a choice about cookies
    const userConsent = localStorage.getItem("cookieConsent");
    if (!userConsent) {
      setIsVisible(true); // Show banner if no choice has been made
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("cookieConsent", "accepted");
    setIsVisible(false);
  };

  const handleReject = () => {
    localStorage.setItem("cookieConsent", "rejected");
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
      <div className="cookie-consent-banner">
        <div className="cookie_img_container">
          <img src={Cookieimg} alt="cookie" />
          We use cookies
        </div>
        <div className="cookie-consent-content">
          <p>
            We use cookies to enhance your browsing experience and improve our
            website's functionality.
            <br /> By clicking 'Accept Cookies,' you consent to the use of all
            cookies. For more information, please read our &nbsp;
            <a
                className="privacy_policy_cookie"
                href="/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
            >
              PRIVACY POLICY
            </a>
            .
          </p>
          <div className="cookie-consent-buttons">
            <button onClick={handleReject} className="reject-button">
              Reject all
            </button>
            <button onClick={handleAccept} className="accept-button">
              Accept all
            </button>
          </div>
        </div>
      </div>
  );
};

export default CookieConsent;
