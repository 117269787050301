import React, {useEffect, useState} from "react";
import './project.css'
import Logo from "../../assets/logo.png";
import Icon from "../../assets/fi_9356210.svg";
import api from "../../utils/api";
import {toast} from "react-toastify";
import Cookies from "js-cookie";
import {Link, useParams} from "react-router-dom";
import useStore from "../../Store";
import CreateProjectModal from "./CreateProjectModal";
import EditModal from "./EditModal";
import ProjectDocumentsCard from "./ProjectDocumentsCard";
import chevron from '../../assets/Chevron_Down.svg'
import CardComponent from "../card/CardComponent";
import ProjectNav from "./ProjectNav";
import Layout from "../Layout/Layout"

const ProjectDetails = () => {
    const { setDrawerOpener} = useStore();

    const token = Cookies.get("access_token");
    const [isOpen, setIsOpen] = useState(true);
    const [projectName, setProjectName] = useState('')
    const [documents, setDocuments] = useState('')

    let { id } = useParams();

    const [isModalOpen, setIsModalOpen] = useState(false);


    const [project, setProject] = useState('')
    useEffect(  () => {
        setDrawerOpener(false)

        const fetchData = async () => {
            try {
                if (!token) {
                    throw new Error("No access token found");
                }

                const response = await api.get(
                    `/projects/${id}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                console.log("data", response.data)
                setProject(response.data.project)
                setProjectName(response.data.project.name)
                setDocuments(response.data.documents)
            } catch (err) {
                toast.error("Error creating project. Please try again.");
            }
        };

        fetchData();
    }, [id]);


    return (
        <Layout>
        <div className="Parent-div">
            <div className="head-nav">
<ProjectNav projectName={projectName} setIsModalOpen={setIsModalOpen} setIsOpen={setIsOpen} />
                <div className="Content">
                    <div style={{display: 'flex'}}>
                        <div style={{width: '70%'}}>
                            <div className="rightContent">
                                <div style={{fontWeight: 800, fontSize: 'large', marginBottom: '20px'}}>
                                    AI Recommendation for you
                                </div>
                            </div>

                            <div className="cards">
                                <div className="card-1">
                                    <div>Main keyword</div>
                                    <div className="article-1">This is the article title</div>
                                    <div className="content-1">
                                        <div className="content-div-1">Create with AI</div>
                                        <div className="content-div-2">
                                            Start from scratch
                                            <img
                                                style={{
                                                    padding: '5px',
                                                    backgroundColor: 'white',
                                                    borderRadius: '15px'
                                                }}
                                                src={chevron}
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="card-1">
                                    <div>Main keyword</div>
                                    <div className="article-1">This is the article title</div>
                                    <div className="content-1">
                                        <div className="content-div-1">Create with AI</div>
                                        <div className="content-div-2">
                                            Start from scratch
                                            <img
                                                style={{
                                                    padding: '5px',
                                                    backgroundColor: 'white',
                                                    borderRadius: '15px'
                                                }}
                                                src={chevron}
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="Content-2">
                            <div className="head">
                                <div className="head-1">Recent documents</div>
                                <div className="head-2 color_blue"><Link className="color_blue" to={`/project/${project.id}/documents`} > SEE ALL </Link></div>
                            </div>

                            {documents && documents.length > 0 ? (
                                documents.map((document, index) => (
                                    <ProjectDocumentsCard
                                        projectName={ document.project_name}
                                        title={document.name}
                                        seoScore={document.seo_score}
                                        status={document.doc_status}
                                        date={document.created_at}
                                        documentBreif={ document.doc_brief}
                                        key={index}
                                    />

                                ))
                            ) : (

                                <ProjectDocumentsCard noCard={true}/>

                            )}
                        </div>
                    </div>
                </div>
                <EditModal open={isModalOpen} setIsModalOpen={setIsModalOpen} id={id} setProjectName={setProjectName} projectName={projectName} project={project}/>

            </div>

        </div>
        </Layout>
    )
};

export default ProjectDetails;
